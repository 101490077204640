import { Grid, Typography, useMediaQuery } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import dayjs from "dayjs"

//const ENDPOINT = "https://castle-tracker.pages.dev/data"
const ENDPOINT = "./data"

function App() {
  const medium = !useMediaQuery("(min-width:900px)")
  const small = !useMediaQuery("(min-width:600px)")
  console.log(small)
  const query = useQuery(
    ["data"],
    () => fetch(ENDPOINT).then(response => response.json()),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  )
  const now = dayjs()

  const data = query.data
  if (data) {
    let max = new Date()
    for (const year in data) {
      for (const month in data[year]) {
        for (const day in data[year][month]) {
          const date = new Date(year, month - 1, day)
          if (date > max) {
            max = date
          }
        }
      }
    }
    max = dayjs(max)
    let previous = null
    let result = []
    let month = []
    let current = now.subtract(now.day(), "day")
    let first = false
    let currentMonth = now.month()
    while (current < max) {
      let week = []
      previous = current
      for (let weekday = 0; weekday < 7; weekday++) {
        let yearInfo = data[current.year()]
        let monthInfo =
          yearInfo && yearInfo[String(current.month() + 1).padStart(2, "0")]
        let dayInfo =
          monthInfo && monthInfo[String(current.date()).padStart(2, "0")]
        let parties = 0
        let skaters = 0
        for (const party of dayInfo ? dayInfo : []) {
          if (party.time === "13:00:00") continue // skip 1 pm session on saturday
          parties++
          skaters += party.skaters ? parseInt(party.skaters) : 0
        }
        let hue = 117
        hue -= skaters * 1.5
        if (hue < 0) hue = 0
        const today =
          current.date() === now.date() && current.month() === now.month()
        week.push(
          <Grid
            item
            key={current}
            sx={{
              width: "14.2857142857%",
              maxWidth: "200px",
              aspectRatio: "1/1",
            }}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent={small ? "center" : "space-evenly"}
              sx={{
                height: "100%",
                borderColor: today ? "#fdd414" : "black",
                borderStyle: "solid",
                borderWidth: today ? "3px" : "1px",
                backgroundColor: `hsl(${hue}, 100%, 60%)`,
                filter:
                  current.month() === currentMonth && current >= now
                    ? "none"
                    : "grayscale(1)",
              }}
            >
              <Grid item>
                <Typography variant={small ? "h6" : medium ? "h3" : "h2"}>
                  {current.date()}
                </Typography>
              </Grid>
              {current >= now ? (
                <Grid item container direction="column" alignItems="center">
                  <Grid item>
                    <Typography
                      variant={small ? "body2" : "body1"}
                      sx={small ? { fontSize: "7pt" } : {}}
                    >
                      {skaters} {skaters === 1 ? "Skater" : "Skaters"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={small ? 0.2 : 0.5}
                  >
                    {[...Array(5).keys()].map(partyIndex => (
                      <Grid item key={partyIndex}>
                        <div
                          style={{
                            height: small ? "5px" : "10px",
                            width: small ? "5px" : "10px",
                            backgroundColor:
                              parties > partyIndex ? "#fdd414" : "white",
                            borderRadius: "50%",
                            borderColor:
                              parties > partyIndex ? "black" : "gray",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }}
                        ></div>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        )
        current = current.add(1, "day")
      }
      month.push(
        <Grid
          container
          item
          direction="row"
          justifyContent="center"
          alignItems="center"
          key={current}
        >
          {week}
        </Grid>
      )
      if (!first && previous.month() !== current.month()) {
        first = true
        result.push(
          <Grid item key={current + "-h"} sx={{ paddingTop: "15px" }}>
            <Typography variant="h4">{previous.format("MMMM YYYY")}</Typography>
          </Grid>
        )
        result.push(
          <Grid container item key={current}>
            {month}
          </Grid>
        )
        month = []
        currentMonth = current.month()
        if (current.startOf("month").day() !== 0) {
          current = current.subtract(1, "week")
        }
      } else {
        first = false
      }
    }
    return (
      <Grid container direction="column" alignItems="center">
        <Grid
          container
          item
          sx={{
            width: "100%",
            backgroundColor: "#cfcfcf",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Grid item direction="column" alignItems="center">
            <Typography variant={small ? "h4" : medium ? "h3" : "h2"}>
              Castle Skateland Party Tracker
            </Typography>
          </Grid>
        </Grid>
        {result}
      </Grid>
    )
  }

  return <div className="App"></div>
}

export default App
